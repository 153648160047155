<template>
  <UITableResponsive :items="items" :headers="['Datum', 'Totaal', 'Met_Klant', 'Zonder_Klant']" class="text-sm">
    <template #colgroup>
      <colgroup>
        <col class="w-48">
        <col class="w-auto">
      </colgroup>
    </template>
    <template #item-Datum="{ item }">
      <span>{{ dateStringLocale(`${props.year}-${props.month}-${item.day}`) }}</span>
    </template>
    <template #item-Totaal="{ item }">
      <span>{{ millisecondsToHMS(item.totaal) }}</span>
      <span v-if="millisecondsToHMS(item.totaal)?.split(':')[0] > 20" class="block border border-orange-300 bg-gray-100 px-1 rounded text-red-500 font-bold text-center">20h+ shift ??</span>
    </template>
    <template #item-Met_Klant="{ item }">
      <span>{{ millisecondsToHMS(item.totaal_met_klant) }}</span>
    </template>
    <template #item-Zonder_Klant="{ item }">
      <span>{{ millisecondsToHMS(Number(item.totaal) - Number(item.totaal_met_klant)) }}</span>
    </template>
  </UITableResponsive>
</template>

<script setup>
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { dateStringLocale, millisecondsToHMS } from '@/functions/formatDate'

const props = defineProps({
  items: { type: Array, required: true },
  year: { type: Number, required: true },
  month: { type: Number, required: true },
})
</script>
