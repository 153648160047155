<template>
  <div>
    <h2>Dag totalen &mdash; Grafiek</h2>
    <div :key="JSON.stringify(chartValues)" class="mt-8 w-full">
      <ChartsBar
        label="Totaal per dag"
        :categories="chartCategories"
        :values="chartValues"
        :options="options"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, defineProps, toRefs } from 'vue'

import { millisecondsToHMSWritten } from '@/functions/formatDate'

const props = defineProps({
  items: {
    type: Array,
    default() {
      return []
    },
  },
})

const ChartsBar = defineAsyncComponent(() => import('@/components/Charts/Bar.vue'))

const { items } = toRefs(props)

const chartCategories = computed(() => {
  if (!items.value) return []
  return items.value.map((item, index) => item.day || (index + 1))
})

const chartValues = computed(() => {
  if (!items.value) return []
  return items.value.map(item => item.totaal <= 0 ? 0 : item.totaal / 1000 / 60 / 60)
})

const options = computed(() => ({
  chart: {
    type: 'bar',
    height: 350,
    zoom: false,
  },

  toolbar: {
    tools: {
      download: '<i class="fas fa-save" width="50" height="20" />',
    },
  },

  dataLabels: {
    enabled: true,
    formatter(val) {
      const milliseconds = Number(val) * 60 * 60 * 1000
      if (milliseconds < 1) return ''
      return millisecondsToHMSWritten(milliseconds)
    },
    style: {
      fontSize: '8px',
      backgroundColor: '#FFF',
      colors: ['#304758'],
    },
  },

  xaxis: {
    type: 'category',
    tickPlacement: 'on',
    categories: chartCategories.value,
    overwriteCategories: chartCategories.value,
    tickAmount: chartCategories.value.length,
    min: chartCategories.value[0],
    max: chartCategories.value[chartCategories.value.length - 1],
  },

  yaxis: {
    enabled: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
  },

  tooltip: {
    y: {
      formatter(val) {
        const milliseconds = Number(val) * 60 * 60 * 1000
        if (milliseconds < 1) return ''
        return millisecondsToHMSWritten(milliseconds)
      },
    },
  },
}))
</script>
