<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Shiften
    </h1>

    <div class="py-4 flex flex-row gap-2">
      <UISelectChauffeur
        v-model="apiData.user_id"
        use-query
        class="max-w-md"
        label
      />
      <UISelectFixed v-model="apiData.month" :disabled="!apiData.user_id" type="MAAND" />
      <UISelectMinMax
        v-model="apiData.year"
        :disabled="!apiData.user_id"
        :min="2018"
        :max="dateNow.getFullYear()"
        label="Jaar"
      />
    </div>

    <div v-if="apiData.user_id > 0" class="flex flex-col gap-4">
      <div class="grid lg:grid-cols-12 gap-4">
        <div class="card mb-4 lg:col-span-4">
          <h2 class="font-semibold text-gray-900">
            Maand totaal
          </h2>
          <ul>
            <li class="flex justify-between">
              <b>Totaal Met Klant:</b> {{ millisecondsToHMS(totaalMetKlant) }}
            </li>
            <li class="flex justify-between">
              <b>+ Totaal Zonder Klant:</b> {{ millisecondsToHMS(totaalZonderKlant) }}
            </li>
            <li class="flex flex-col">
              <div class="flex justify-between bg-yellow-200 p-1 border-t-2 border-dashboard-dark rounded-b -mr-1 ml-4 font-bold">
                <b>= Totale ingevulde tijd:</b> {{ millisecondsToHMS(totaal) }}
              </div>
            </li>
            <template v-if="isVolledigeLijstBeschikbaar">
              <li class="flex justify-between">
                <b>- Overlapping tijd (genegeerd):</b> {{ millisecondsToHMS(totaalOverlappingNegeren) }}
              </li>
              <li class="flex flex-col">
                <div class="flex justify-between bg-green-300 p-1 border-t-2 border-dashboard-dark rounded-b -mr-1 ml-4 font-bold">
                  <b>= Totale werktijd:</b> {{ millisecondsToHMS(totaal - totaalOverlappingNegeren) }}
                </div>
              </li>
              <li class="flex justify-between">
                <b>- Tijd op Ophaallocatie:</b> {{ millisecondsToHMS(totaalWachtendOphaallocatie) }}
              </li>
              <li class="flex flex-col">
                <div class="flex justify-between bg-yellow-200 p-1 border-t-2 border-dashboard-dark rounded-b -mr-1 ml-4 font-bold">
                  <b>= Totaal productieve tijd:</b> {{ millisecondsToHMS(totaal - totaalOverlappingNegeren - totaalWachtendOphaallocatie) }}
                </div>
              </li>
            </template>
          </ul>
        </div>

        <div v-if="store.getters.hasKantoorPermission" class="card lg:col-span-8">
          <h2 class="font-semibold text-gray-900">
            Dag totalen - Lijst
          </h2>
          <div class="grid md:grid-cols-2 gap-2">
            <TableShiftenDagTotalen :items="itemsDaily.slice(0, 15)" :year="apiData.year" :month="apiData.month" />
            <TableShiftenDagTotalen :items="itemsDaily.slice(15)" :year="apiData.year" :month="apiData.month" />
          </div>
        </div>
      </div>

      <div v-if="isVolledigeLijstBeschikbaar" class="card">
        <h2 class="font-semibold text-gray-900">
          Lijst van alle shiften
        </h2>
        <UITableResponsive :items="data?.list" :headers="headers" class="text-sm">
          <template #item-Start="{ item }">
            <span v-if="!item.start" class="shift-attention-box">Niet ingevuld?</span>
            <router-link
              v-else
              :to="{ name: 'Kalender', query: { date: dateString(item.rit_datum), rit_id: item.rit_id, retour: item.is_retour } }"
              target="_blank"
              class="inline-flex flex-col items-start gap-1 hover:underline"
            >
              <span
                :class="{ 'line-through': item.start_overlap }"
                class="font-bold"
              >
                {{ dateTimeString(item.start) }}
              </span>
              <span v-if="item.start_overlap" class="shift-attention-box font-bold">
                * {{ dateTimeString(item.start_overlap) }}
              </span>
              <UITextShiftOverlapping v-if="item.start_overlap" class="shift-attention-box" />
            </router-link>

            <router-link
              v-if="!item.start"
              :to="{ name: 'Kalender', query: { date: dateString(item.rit_datum), rit_id: item.rit_id, retour: item.is_retour } }"
              target="_blank"
              class="flex flex-col gap-0 text-xs leading-0 mt-0.5 hover:underline pl-1"
            >
              <b>Rit datum:</b><br><small>{{ dateWrittenTimeString(item.rit_datum) }}</small>
            </router-link>
            <UIBadgeShiftClamped v-else-if="item.start_isClamped" class="inline ml-1" />
          </template>

          <template #item-Aangekomen="{ item }">
            <span v-if="!item.aangekomen" class="shift-attention-box">Niet ingevuld?</span>
            <div v-else class="inline-flex flex-col items-start gap-1 italic text-xs">
              <span :class="{ 'line-through': item.aangekomen_overlap }">{{ dateTimeString(item.aangekomen) }}</span>
              <span v-if="item.aangekomen_overlap" class="shift-attention-box">
                * {{ dateTimeString(item.aangekomen_overlap) }}
              </span>
              <UITextShiftOverlapping v-if="item.aangekomen_overlap" class="shift-attention-box" />
            </div>
          </template>

          <template #item-Klant_ingestapt="{ item }">
            <span v-if="!item.klant_ingestapt" class="shift-attention-box">Niet ingevuld?</span>
            <div v-else class="inline-flex flex-col items-start gap-1 italic text-xs">
              <span :class="{ 'line-through': item.klant_ingestapt_overlap }">{{ dateTimeString(item.klant_ingestapt) }}</span>
              <span v-if="item.klant_ingestapt_overlap" class="shift-attention-box">
                * {{ dateTimeString(item.klant_ingestapt_overlap) }}
              </span>
              <UITextShiftOverlapping v-if="item.klant_ingestapt_overlap" class="shift-attention-box" />
            </div>
          </template>

          <template #item-Klant_afgezet="{ item }">
            <span v-if="!item.klant_afgezet" class="shift-attention-box">Niet ingevuld?</span>
            <div v-else class="inline-flex flex-col items-start gap-1 italic text-xs">
              <span :class="{ 'line-through': item.klant_afgezet_overlap }">{{ dateTimeString(item.klant_afgezet) }}</span>
              <span v-if="item.klant_afgezet_overlap" class="shift-attention-box">
                * {{ dateTimeString(item.klant_afgezet_overlap) }}
              </span>
              <UITextShiftOverlapping v-if="item.klant_afgezet_overlap" class="shift-attention-box" />
            </div>
          </template>

          <template #item-Einde="{ item }">
            <span v-if="!item.einde" class="shift-attention-box">Niet ingevuld?</span>
            <div v-else class="inline-flex flex-col items-start gap-1">
              <span :class="{ 'line-through': item.einde_overlap }" class="font-bold">{{ dateTimeString(item.einde) }}</span>
              <span v-if="item.einde_overlap" class="shift-attention-box font-bold">
                * {{ dateTimeString(item.einde_overlap) }}
              </span>
              <UITextShiftOverlapping v-if="item.einde_overlap" class="shift-attention-box" />
            </div>
            <UIBadgeShiftClamped v-if="item.einde_isClamped" class="inline ml-1" />
          </template>

          <template #item-Wachtend_Ophaallocatie="{ item }">
            <span v-if="!item.aangekomen || !item.klant_ingestapt" class="shift-attention-box">Shift niet volledig!</span>
            <div v-else class="inline-flex flex-col items-start gap-1" :class="{ 'shift-attention-text': item.aangekomen_overlap || item.klant_ingestapt_overlap }">
              {{ millisecondsToHMS(berekenWachtendOphaallocatie(item)) }}
            </div>
          </template>

          <template #item-Tijd_Met_Klant="{ item }">
            <span v-if="!item.klant_ingestapt || !item.klant_afgezet" class="shift-attention-box">Shift niet volledig!</span>
            <div v-else class="inline-flex flex-col items-start gap-1" :class="{ 'shift-attention-text': item.klant_ingestapt_overlap || item.klant_afgezet_overlap }">
              {{ millisecondsToHMS(berekenMetKlant(item)) }}
            </div>
            <span v-if="item.is_no_show" class="shift-attention-box text-center">NO SHOW!</span>
          </template>

          <template #item-Tijd_Zonder_Klant="{ item }">
            <span v-if="!item.start || !item.einde || !item.klant_ingestapt || !item.klant_afgezet" class="shift-attention-box">Shift niet volledig!</span>
            <div v-else class="inline-flex flex-col items-start gap-1" :class="{ 'shift-attention-text': item.start_overlap || item.einde_overlap || item.klant_ingestapt_overlap || item.klant_afgezet_overlap }">
              {{ millisecondsToHMS(berekenZonderKlant(item)) }}
            </div>
          </template>

          <template #item-Totale_Tijd="{ item }">
            <div class="flex flex-col items-start gap-1">
              <span :class="{ 'shift-attention-box font-bold': item.time <= 0, 'shift-attention-text': item.start_overlap || item.einde_overlap || item.klant_ingestapt_overlap || item.klant_afgezet_overlap }">
                {{ millisecondsToHMS(item.time) }}
              </span>
              <ButtonRitCombinatieStatus v-if="item.combinatie_status > 0" :status="item.combinatie_status" disabled />
            </div>
          </template>

          <template #item-Acties="{ item }">
            <div class="inline-flex flex-col gap-1">
              <button v-if="isActiesBeschikbaar" class="btn warning" @click="handleEditShift(item)">
                <i class="fas fa-edit"></i> Bewerken
              </button>
              <ButtonRitInfo
                :id="item.rit_id"
                :disabled="!(item.rit_id > 0)"
                :deel="item.is_retour ? 'retour' : 'heen'"
                target="_blank"
              />
            </div>
          </template>
        </UITableResponsive>
      </div>

      <BarShiften v-if="isGrafiekBeschikbaar" class="card" :items="itemsDaily" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import BarShiften from '@/components/Charts/BarShiften.vue'
import TableShiftenDagTotalen from '@/components/Table/Shiften/DagTotalen.vue'
import UIBadgeShiftClamped from '@/components/UI/Badge/ShiftClamped.vue'
import ButtonRitCombinatieStatus from '@/components/UI/Button/RitCombinatieStatus.vue'
import ButtonRitInfo from '@/components/UI/Button/RitInfo.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import UITextShiftOverlapping from '@/components/UI/Text/ShiftOverlapping.vue'

import { dateString, dateTimeString, dateWrittenTimeString, millisecondsToHMS } from '@/functions/formatDate'
import useGetApi from '@/hooks/useGetApi'
import useModal from '@/hooks/useModal'
import useRouteQueryInt from '@/hooks/useRouteQueryInt'

const store = useStore()
const [openFixedModal] = useModal({ fixed: true })

const isActiesBeschikbaar = computed(() => store.getters.hasAdminPermission)
const isGrafiekBeschikbaar = computed(() => store.getters.hasAdminPermission)
const isVolledigeLijstBeschikbaar = computed(() => store.getters.hasAdminPermission)

const headers = computed(() => {
  return [
    'Start',
    'Aangekomen',
    'Klant_ingestapt',
    'Klant_afgezet',
    'Einde',
    'Wachtend_Ophaallocatie',
    'Tijd_Met_Klant',
    'Tijd_Zonder_Klant',
    'Totale_Tijd',
    isActiesBeschikbaar.value && 'Acties',
  ].filter(el => el)
})

const dateNow = new Date()
const apiData = ref({
  user_id: useRouteQueryInt('chauffeur').value || store.state.profile?.id || null,
  month: dateNow.getMonth() + 1,
  year: dateNow.getFullYear(),
})

const { data, getData } = useGetApi('/api/dashboard/shiften/list', apiData, { watch: true, skipMounted: true })

const minimalTime = computed(() => new Date(apiData.value.year, apiData.value.month - 1, 1).getTime())
const parseMinimalTime = time => Math.max(minimalTime.value, new Date(time || 0).getTime())

const berekenWachtendOphaallocatie = item => {
  if (!item.aangekomen || !item.klant_ingestapt) return 0
  return parseMinimalTime(item.klant_ingestapt_overlap || item.klant_ingestapt)
    - parseMinimalTime(item.aangekomen_overlap || item.aangekomen)
}

const berekenMetKlant = item => {
  if (!item.klant_ingestapt || !item.klant_afgezet) return 0
  return parseMinimalTime(item.klant_afgezet_overlap || item.klant_afgezet)
    - parseMinimalTime(item.klant_ingestapt_overlap || item.klant_ingestapt)
}

const berekenZonderKlant = item => {
  if (!item.start || !item.einde) return 0
  const metKlant = berekenMetKlant(item)
  const totaal = parseMinimalTime(item.einde_overlap || item.einde) - parseMinimalTime(item.start_overlap || item.start)
  return totaal - metKlant
}

const handleEditShift = shiftItem => {
  const shift = Object.assign({}, { ...shiftItem })
  shift.start = shift.start_original || shift.start
  shift.aangekomen = shift.aangekomen_original || shift.aangekomen
  shift.klant_ingestapt = shift.klant_ingestapt_original || shift.klant_ingestapt
  shift.klant_afgezet = shift.klant_afgezet_original || shift.klant_afgezet
  shift.einde = shift.einde_original || shift.einde

  delete shift.start_original
  delete shift.aangekomen_original
  delete shift.klant_ingestapt_original
  delete shift.klant_afgezet_original
  delete shift.einde_original

  openFixedModal('FORM_SHIFT', { shift, callback: getData })
}

const itemsDaily = computed(() => {
  return Object.entries(data?.value?.daily || {})
    .map(([day, { totaal, totaal_met_klant }]) => ({ day, totaal, totaal_met_klant }))
})

const totaal = computed(() => itemsDaily.value.reduce((prev, next) => prev + (next.totaal || 0), 0))
const totaalOverlappingNegeren = computed(() => {
  let time = 0
  data?.value?.list?.forEach(el => {
    if (el.start_overlap) time += new Date(el.start_overlap).getTime() - new Date(el.start).getTime()
    if (el.einde_overlap) time += new Date(el.einde_overlap).getTime() - new Date(el.einde).getTime()
  })
  return time
})
const totaalWachtendOphaallocatie = computed(() => data?.value?.list.reduce((prev, next) => prev + berekenWachtendOphaallocatie(next), 0))
const totaalMetKlant = computed(() => data?.value?.list.reduce((prev, next) => prev + berekenMetKlant(next), 0))
const totaalZonderKlant = computed(() => data?.value?.list.reduce((prev, next) => prev + berekenZonderKlant(next), 0))
</script>

<style lang="scss" scoped>
ul {
  border-collapse: collapse;
  @apply border border-dashboard-dark;
}

ul li {
  @apply border border-dashboard-dark;
  @apply px-2 py-1 pr-4;
  border-collapse: collapse;
}

.shift-attention-text {
  @apply text-orange-500 italic font-semibold;
}

.shift-attention-box {
  @apply border border-orange-300 bg-gray-100 px-1 rounded text-orange-500 font-bold text-xs;
}
</style>
