<template>
  <span class="shift-attention-box" @click="showAlert">
    <i class="fas fa-exclamation-triangle text-orange-500 hover:text-orange-600 cursor-pointer"></i>
    Overlapping
  </span>
</template>

<script setup>
const showAlert = () => {
  alert('Dit betekent dat deze shift overlapt, voor de berekening herleiden we deze tijd naar de eerste niet-overlappende tijd.')
}
</script>
